import React, { useState } from 'react';
import { graphql } from 'gatsby';

// Hooks Querys & Context
import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

import Layout from '@components/layout';
import ExploreMenu from '@components/fixed/exploreMenu';
import { PageMeta } from '@components/pageMeta';
import SimplePortableText from '@components/sanity/simplePortableText';
import PortalModal from '@components/UI/portalModal';
import BlueVector from '@svg/bluevector.svg';
import cx from 'classnames';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { StandardInput } from '@components/UI/inputs';
import { useLocation } from '@reach/router';
import { motion } from 'framer-motion';

import BlueCross from '@svg/bluecross.svg';

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

// Form Boilerplate
const initialValues = {
  fullName: '',
  phone: '',
  email: '',
};

const FormSchema = Yup.object().shape({
  fullName: Yup.string().required('Name Required'),
  email: Yup.string().email('Invalid Email').required('Email Is Required'),
  message: Yup.string().required('Message Required'),
  phone: Yup.string().required('Phone Required'),
});

const EnquireForm = ({ setModalOpen }) => {
  const { pathname } = useLocation();

  const handleClose = () => setModalOpen(false);

  return (
    <div className='bg-white w-full h-full px-gutter  pt-11  md:mx-auto self-center md:max-w-4xl md:max-h-[700px] relative'>
      <motion.div
        whileHover={{
          scale: 1.2,
        }}
        onClick={handleClose}
        className='absolute top-10 right-10 cursor-pointer bg-light-blue rounded-full h-16 w-16 '>
        <BlueCross className='w-[40px] absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2' />
      </motion.div>
      <h1 className='blockH4 text-grey mb-24'>Enquire Now</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={FormSchema}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          fetch(pathname || '/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({ 'form-name': 'contact', ...values }),
          })
            .then(() => {
              setSubmitting(false);
              setStatus('success');
            })
            .catch((error) => alert(error));
        }}>
        {({
          onChange,
          isSubmitting,
          status,
          /* and other goodies */
        }) => (
          <Form
            name='contact'
            data-netlify='true'
            className=' flex flex-col   '>
            <input type='hidden' name='form-name' value='contact' />

            {status === 'success' ? (
              <div className='blockH6 text-grey'>
                Thank you, we will be in touch shortly!
              </div>
            ) : (
              <React.Fragment>
                <div className=''>
                  <StandardInput name='fullName' placeholder='Full Name' />
                </div>
                <div className='h-[2px] bg-grey/50 w-full' />

                <div className='-ml-3'>
                  <StandardInput
                    name='email'
                    placeholder='Email'
                    type='email'
                  />
                </div>
                <div className='h-[2px] bg-grey/50 w-full' />
                <div className=''>
                  <StandardInput name='phone' placeholder='Phone' />
                </div>
                <div className='h-[2px] bg-grey/50 w-full' />

                <div className='-ml-3'>
                  <StandardInput
                    name='message'
                    placeholder='Message'
                    as='textarea'
                    rows='4'
                  />
                </div>
                <div className='h-[2px] bg-grey/50 w-full' />

                <button
                  className=' button-blue  self-end w-[fit-content] flex mt-9 cursor-pointer'
                  type='Submit enquiry'>
                  <p className='blockH8 font-medium self-center inline-block pr-4 '>
                    Submit enquiry
                  </p>
                  <div className='inline-block  p-3 rounded-full bg-blue/20'>
                    <BlueVector className='  w-4 h-4  ' />
                  </div>
                </button>
              </React.Fragment>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

const ContactPage = ({ data: { page } }) => {
  const { title, headerText, addresses, seo } = page || {};
  const [modalOpen, setModalOpen] = useState(false);

  const [isHover, setIsHover] = useState(false);

  return (
    <Layout reverseHeader={true}>
      <PageMeta {...seo} />

      <div className='px-gutter pt-36 md:pt-96 pb-10 md:pb-0  text-grey bg-light-blue'>
        <h1 className='richH0 pb-2  lg:pb-9'>{title}</h1>
        <p className='richH2 '>{headerText}</p>
        {/* remove contact form until further notice */}
        {/* <div
          className=" button-blue group w-[fit-content] flex mt-5 cursor-pointer "
          onClick={() => setModalOpen(true)}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <p className="blockH8 font-medium  self-center inline-block pr-4 ">
            Enquire now
          </p>
          <div className="inline-block  p-3 rounded-full bg-blue/20 group-hover:bg-blue/80 transition-all ease-in-out duration-700">
            <BlueVector
              className={cx("w-4 h-4 ", {
                "svg-white ": isHover,
              })}
            />
          </div>
        </div> */}
      </div>

      <PortalModal isShowing={modalOpen}>
        <EnquireForm setModalOpen={setModalOpen} />
      </PortalModal>

      <div className='bg-light-blue pt-space-m px-gutter grid grid-cols-1 md:grid-cols-3 gap-x-10 lg:gap-x-32 gap-y-10 md:gap-y-36'>
        {addresses?.map((address, i) => (
          <div>
            <h1 className='richH3 text-blue mb-7'>{address?.addressName}</h1>
            <SimplePortableText text={address?.addressDetails} />
          </div>
        ))}
      </div>
      <ExploreMenu />
    </Layout>
  );
};

export default ContactPage;

export const pageQuery = graphql`
  query contactPageQuery {
    page: sanityContactPage {
      seo {
        ...SEO
      }
      title
      headerText
      addresses {
        addressName
        addressDetails: _rawAddressDetails(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`;
